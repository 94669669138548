<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card class="v-card-bottom-30">
      <v-card-title>
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        ภาพรวมการเดินสต๊อก
      </v-card-title>
    </v-card>

    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <div class="py-1">
          <v-icon size="20" color="primary">fa-sliders-h</v-icon
          ><span class="my-3 ml-3">เลือกข้อมูลที่ต้องการ</span>
        </div>
        <v-spacer />
        <div v-show="showTable">
          <v-col cols="12" md="2" class="pb-3">
            <vue-excel-xlsx
              id="GetExcelAll"
              v-show="false"
              :data="datasetExcel"
              :columns="headerExcel"
              :file-name="ExcelName"
              :sheet-name="'sheet1'"
            >
            </vue-excel-xlsx>
            <v-btn
              :elevation="1"
              outlined
              color="success"
              class="pa-2 ml-2 mt-1"
              @click="exportexcel()"
            >
              <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
              <span class="fn-12"> Export Excel </span>
              <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
            </v-btn>
          </v-col>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="findStock" lazy-validation>
          <!-- เลือก-->
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-3">
              <span>วันที่ : </span>
            </v-col>
            <v-col cols="12" lg="2" class="pt-1 pb-2">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    outlined
                    readonly
                    :rules="datestartValidate"
                    label="เริ่มต้น"
                    prepend-inner-icon="mdi-calendar"
                    @blur="startDate = parseDate(dateStart)"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :disabled="disableDate"
                    @click:clear="dateStart = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menuStartDate = false"
                  @change="
                    (dateStart = formatDate(startDate)),
                      CheckDateTime(0, startDate, endDate)
                  "
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="2" class="pt-1 pb-2">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    outlined
                    readonly
                    :rules="dateendValidate"
                    label="สิ้นสุด"
                    prepend-inner-icon="mdi-calendar"
                    @blur="endDate = parseDate(dateEnd)"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :disabled="disableDate"
                    @click:clear="dateEnd = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menuEndDate = false"
                  @change="
                    (dateEnd = formatDate(endDate)), CheckDateTime(0, startDate, endDate)
                  "
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="12" md="2" lg="1" class="pb-3">
              <span>เลือกร้านค้า : </span>
            </v-col>
            <v-col cols="12" lg="4" class="pt-1 pb-2">
              <v-combobox
                v-model="sh_shop_id"
                :items="sh_shop_list"
                @input.native="course = $event.srcElement.value"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                dense
              ></v-combobox>
            </v-col>
            <v-col cols="12" lg="2" class="pt-2 pb-2"> </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="12" md="2" lg="1" class="pb-3">
              <span>เลือกคลัง : </span>
            </v-col>
            <v-col cols="12" lg="4" class="pt-1 pb-2">
              <v-combobox
                v-model="cb_warehouse_selected"
                :items="warehouse_list"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                return-object
                dense
              ></v-combobox>
            </v-col>
            <v-col cols="12" lg="2" class="pt-2 pb-2">
              <v-btn
                dark
                color="primary"
                @click="HandleFindStockinfoClick(limits, 0, 1)"
                class="mr-3"
                >ค้นหา</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row class="ma-0 py-1" justify="space-between" align="center" v-show="showTable">
      <v-col cols="8" class="pa-0">
        <table>
          <td class="p-15 w-13r">
            <span class="head-doc"> จำนวนแถว </span> ทั้งหมด {{ formatMoney(total) }} แถว
          </td>
        </table>
      </v-col>
      <v-col cols="2" class="pa-0 hidden-xs-only" align="right">
        <v-btn color="" class="pa-2 ml-2 mt-1" icon @click="changeFormat_DataTabel()">
          <v-icon v-if="!formatDataTabel" size="16" class="pr-1">fa-table</v-icon>
          <v-icon v-else size="16" class="pr-1">fa-list</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- แสดงรายการสต็อก -->
    <!-- PC hidden-xs-only -->
    <v-data-table
      v-show="showTable"
      :headers="showHeaders"
      :items="summarizeStock.data"
      :items-per-page5="9999999999"
      hide-default-footer
      class="packhai-border-table hidden-xs-only"
    >
      <template
        v-for="(header, index) in summarizeStock.headerTable"
        v-slot:[`header.${header.value}`]="{ header }"
      >
        <v-tooltip :key="index" v-slot:activator="{ on }">
          <span>
            {{
              header.text == "ชื่อ" && !formatDataTabel
                ? "รายละเอียดเบื้องต้น"
                : header.text
            }}</span
          >
        </v-tooltip>
      </template>
      <template v-slot:item.No="{ item }">
        {{ summarizeStock.data.indexOf(item) + 1 + offsets }}
      </template>
      <template v-slot:item.photoLink="{ item }">
        <a
          v-if="item.photoLink != null && item.photoLink != ''"
          icon
          @click="OpenPhoto(item.photoLink)"
        >
          <img :src="item.photoLink" class="picsize mt-1" />
        </a>
        <a v-else>
          <img
            src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG"
            class="picsize mt-1"
          />
        </a>
      </template>

      <template v-slot:item.name="{ item }">
        <a @click="gotoStock_InFo(item)">
          <v-row>
            <v-col cols="12" align="left" class="">
              <span class="fn-13" style="font-weight: bold">{{ item.name }}</span>
            </v-col>
          </v-row>
        </a>
        <template v-if="!formatDataTabel">
          <v-row>
            <v-col cols="12" align="left" class="">
              <span class="color-gray-13"
                >{{ !item.barcode ? "" : "barcode" + " : " }} {{ item.barcode }}
                {{ item.barcode && item.sku ? "," : "" }}
                {{ !item.sku ? "" : "SKU" + " : " }} {{ item.sku }}</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" align="left" class="">
              <span class="color-gray-13">
                {{ !item.productCode ? "" : "รหัสสินค้า" + " : " }}
                {{ item.productCode }}
                {{
                  item.productCode && (item.prop1_description || item.prop2_description)
                    ? ","
                    : ""
                }}
                {{
                  !item.prop1_description && !item.prop2_description
                    ? ""
                    : "ลักษณะ" + " : "
                }}
                {{ item.prop1_description }} {{ item.prop2_description }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" align="left" class="">
              <span class="color-gray-13"
                >{{ !item.cfCode ? "" : "รหัสCF" + " : " }} {{ item.cfCode }}
                {{ item.cfCode && item.RackNo ? "," : "" }}
                {{ !item.RackNo ? "" : "RackNo" + " : " }}
                {{ item.RackNo }}</span
              >
            </v-col>
          </v-row>
        </template>
      </template>
      <template v-slot:item.props="{ item }">
        <span class="fn-13">
          {{
            (item.prop1_description != null && item.prop1_description != ""
              ? item.prop1_description
              : "") +
            (item.prop2_description != null && item.prop2_description != ""
              ? " , " + item.prop2_description
              : "")
          }}
        </span>
      </template>
      <template v-slot:item.quantityStart="{ item }">
        {{ formatMoney(item.quantityStart) }}
      </template>
      <template v-slot:item.quantityOrder="{ item }">
        {{ formatMoney(item.quantityOrder) }}
      </template>
      <template v-slot:item.quantityReturn="{ item }">
        {{ formatMoney(item.quantityReturn) }}
      </template>
      <template v-slot:item.quantityImport="{ item }">
        {{ formatMoney(item.quantityImport) }}
      </template>
      <template v-slot:item.quantityExport="{ item }">
        {{ formatMoney(item.quantityExport) }}
      </template>
      <template v-slot:item.quantityRemain="{ item }">
        {{ formatMoney(item.quantityRemain) }}
      </template>
      <template v-slot:item.detaildialog="{ item }">
        <a @click="detailDialog(item)">รายละเอียด</a>
      </template>
    </v-data-table>

    <!-- Mobile hidden-sm-and-up -->
    <v-data-iterator
      v-show="showTable"
      :items="summarizeStock.data"
      :items-per-page="9999999999"
      hide-default-header
      hide-default-footer
      class="hidden-sm-and-up"
    >
      <template v-slot:default="props">
        <v-row class="ma-0">
          <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
            <v-card>
              <v-card-title class="subheading font-weight-bold pa-0">
                <div class="px-0 v-list-item">
                  <a
                    v-if="item.photoLink != null && item.photoLink != ''"
                    @click="OpenPhoto(item.photoLink)"
                    class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile"
                  >
                    <img :src="item.photoLink" class="picsize" />
                  </a>
                  <a
                    v-else
                    class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                    style="height: 90px; min-width: 90px; width: 90px"
                  >
                    <span class="bold-color-gray-12">ไม่มีรูป </span>
                  </a>
                  <div class="v-list-item__content pa-2 pl-0">
                    <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                      <div>
                        <div>ชื่อ</div>
                        <div style="line-height: 1rem !important">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="cut-text">
                                <span v-if="item.name != null">: {{ item.name }}</span>
                              </div>
                            </template>
                            <span v-if="item.name != null">{{ item.name }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="v-markdown subtitle-1 packhai-showproduct-2">
                        <div>
                          <div>ลักษณะ</div>
                          <div class="cut-text">
                            :
                            {{
                              (item.prop1_description != null &&
                              item.prop1_description != ""
                                ? item.prop1_description
                                : "") +
                              (item.prop2_description != null &&
                              item.prop2_description != ""
                                ? " , " + item.prop2_description
                                : "")
                            }}
                          </div>
                        </div>

                        <br />
                        <div>
                          <div>รหัสสินค้า</div>
                          <div class="cut-text">: {{ item.productCode }}</div>
                        </div>

                        <br />
                        <div>
                          <div>SKU</div>
                          <div class="cut-text">: {{ item.sku }}</div>
                        </div>

                        <br />
                        <div>
                          <div>Barcode</div>
                          <div class="cut-text">: {{ item.barcode }}</div>
                        </div>
                        <br />
                        <div>
                          <div>รายละเอียด</div>
                          <div class="cut-text">
                            <a @click="detailDialog(item)">รายละเอียด</a>
                          </div>
                        </div>
                        <br />
                        <div>
                          <v-row class="packhai-showproduct-3 pa-1">
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนต้นงวด</b>
                            </v-col>
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนออเดอร์</b>
                            </v-col>
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนตีกลับ</b>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                <b>
                                  {{ formatMoney(item.quantityStart) }}
                                </b>
                              </span>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                {{ formatMoney(item.quantityOrder) }}
                              </span>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                {{ formatMoney(item.quantityReturn) }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>

                        <v-divider />
                        <br />
                        <div>
                          <v-row class="packhai-showproduct-3 pa-1">
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนนำเข้า</b>
                            </v-col>
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนนำออก</b>
                            </v-col>
                            <v-col cols="3" class="text-center py-0">
                              <b>จำนวนคงเหลือ</b>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                <b>
                                  {{ formatMoney(item.quantityImport) }}
                                </b>
                              </span>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                {{ formatMoney(item.quantityExport) }}
                              </span>
                            </v-col>
                            <v-col cols="3" class="text-center pa-2">
                              <span>
                                {{ formatMoney(item.quantityRemain) }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- เปลี่ยนหน้า -->
    <v-row
      v-show="showTable"
      v-if="pageLength > 1 && summarizeStock.data.length > 0"
      class="text-center pa-2"
      @click="ChangePage()"
    >
      <v-col cols="12" class="pa-0">
        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="error"
        ></v-pagination>
      </v-col>
    </v-row>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแท็บใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- แสดงรายละเอียด -->
    <v-dialog v-model="summarizeStock.dialog" scrollable max-width="70%" persistent>
      <v-card>
        <v-card-title>
          <span>รายละเอียดรายการเดินสต๊อก</span>
          <v-spacer></v-spacer>
          <vue-excel-xlsx
            id="GetExcel"
            v-show="false"
            :data="datasetExcel1"
            :columns="headerExcel1"
            :file-name="ExcelName1"
            :sheet-name="'sheet1'"
          >
          </vue-excel-xlsx>
          <v-btn
            :elevation="1"
            outlined
            color="success"
            class="pa-2 ml-2 mt-1"
            @click="exportexcelDialog()"
          >
            <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
            <span class="fn-12"> Export Excel </span>
            <span v-if="loadingExcel1 != 0">({{ loadingExcel1 }}%)</span>
          </v-btn>

          <v-btn icon @click="summarizeStock.dialog = false"
            ><v-icon class="fn-25">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div>
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="header2"
              :items="dataset2"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table hidden-xs-only mt-2"
            >
              <template v-slot:item.referenceNo="{ item }">
                <a @click="NewPage(item.referenceNo)">
                  {{ item.referenceNo }}
                </a>
              </template>
              <template v-slot:item.description="{ item }">
                <font v-if="item.removeQuantity > 0" class="pl-10">{{
                  item.description
                }}</font>
                <span v-else> {{ item.description }} </span>
              </template>
              <template v-slot:item.addQuantity="{ item }">
                <span class="color-green"
                  ><b> {{ item.addQuantity }} </b></span
                >
              </template>
              <template v-slot:item.removeQuantity="{ item }">
                <span class="color-red"
                  ><b> {{ item.removeQuantity }} </b></span
                >
              </template>
              <template v-slot:item.totalQuantity="{ item }">
                <b> {{ item.totalQuantity }} </b>
              </template>
            </v-data-table>

            <!-- Mobile hidden-sm-and-up -->
            <v-data-iterator
              :items="dataset2"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.id"
                    cols="12"
                    class="pa-1"
                  >
                    <v-card>
                      <v-row class="ma-0 px-2 py-1">
                        <a @click="NewPage(item.referenceNo)">
                          {{ item.referenceNo }}
                        </a>
                        <v-spacer />
                        {{ item.createdDatetime }}
                      </v-row>
                      <v-divider />
                      <v-row class="px-2 py-1">
                        <v-col cols="4" class="text-center pa-0">
                          <b>นำเข้า</b>
                        </v-col>
                        <v-col cols="4" class="text-center pa-0">
                          <b>นำออก</b>
                        </v-col>
                        <v-col cols="4" class="text-center pa-0">
                          <b>คงเหลือ</b>
                        </v-col>
                      </v-row>
                      <v-row class="px-2 py-1">
                        <v-col cols="4" class="text-center pa-0">
                          <span class="color-green"
                            ><b> {{ item.addQuantity }} </b></span
                          >
                        </v-col>
                        <v-col cols="4" class="text-center pa-0">
                          <span class="color-red"
                            ><b> {{ item.removeQuantity }} </b></span
                          >
                        </v-col>
                        <v-col cols="4" class="text-center pa-0">
                          <b> {{ item.totalQuantity }} </b>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "@/website/components/Loading";
import axios from "axios";
import { utils, writeFileXLSX } from 'xlsx';
import Swal from "sweetalert2";
import {
  branchService_dotnet,
  stockService_dotnet,
  orderService_dotnet,
  shopService_dotnet,
} from "@/website/global";
import {
  formatDatetime,
  formatMoney,
  isNumberWNoDot,
  isNumberWithDot,
  isNumberWitMark,
  set_format_date_time,
  UpdateViewTypePage,
  ViewTypePage,
  format_vat_type,
  format_price,
  GenIE,
  GenPA,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,
    warehouse_list: [],
    cb_warehouse_selected: null,
    // Export Excel
    loadingExcel: 0,
    datasetExcel: [],
    headerExcel: [
      { label: "No", field: "no" },
      { label: "ชื่อ", field: "name" },
      { label: "รหัสCF", field: "cfCode" },
      { label: "รหัสสินค้า", field: "productCode" },
      { label: "ลักษณะ", field: "props" },
      { label: "SKU", field: "sku" },
      { label: "บาร์โค้ด", field: "barcode" },
      { label: "คงเหลือต้นงวด", field: "quantityStart" },
      { label: "แพ็คออกจากออเดอร์", field: "quantityOrder" },
      { label: "ตีกลับ", field: "quantityReturn" },
      { label: "นำเข้า", field: "quantityImport" },
      { label: "นำออก", field: "quantityExport" },
      { label: "คงเหลือสิ้นงวด", field: "quantityRemain" },
      { label: "ร้านค้า", field: "shopName" },
      { label: "คลัง", field: "warehouseName" },
    ],
    ExcelName: null,

    // Export Excel dialog
    loadingExcel1: 0,
    datasetExcel2: [],
    headerExcel1: [
      { label: "วันที่", field: "createdDatetime" },
      { label: "เลขอ้างอิง", field: "referenceNo" },
      { label: "รายละเอียด", field: "description" },
      { label: "นำเข้า", field: "addQuantity" },
      { label: "นำออก", field: "removeQuantity" },
      { label: "คงเหลือ", field: "totalQuantity" },
    ],
    ExcelName2: null,

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 10,
    offsets: 0,
    total: 0,

    // ค้นหาวันที่
    startDate:
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 8) + "01",
    dateStart:
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 8) + "01",
    menuStartDate: false,
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuEndDate: false,
    disableDate: false,

    //Tab2
    header2: [
      {
        text: "วันที่",
        align: "center",
        sortable: false,
        value: "createdDatetime",
        width: "170px",
      },
      {
        text: "เลขอ้างอิง",
        align: "center",
        sortable: false,
        value: "referenceNo",
        width: "110px",
      },
      {
        text: "รายละเอียด",
        align: "left",
        sortable: false,
        value: "description",
      },
      {
        text: "นำเข้า",
        align: "right",
        sortable: false,
        value: "addQuantity",
        width: "90px",
      },
      {
        text: "นำออก",
        align: "right",
        sortable: false,
        value: "removeQuantity",
        width: "90px",
      },
      {
        text: "คงเหลือ",
        align: "right",
        sortable: false,
        value: "totalQuantity",
        width: "90px",
      },
    ],
    dataset2: [],
    search2: {
      warehouseList: [],
      warehouse: { id: null, name: "--ทั้งหมด--" },
      startDate: null,
      endDate: null,
    },
    ExcelName1: "",
    //ตาราง
    showTable: false,
    formatDataTabel: true,
    summarizeStock: {
      data: [],
      dialog: false,
      maxWidthDialogshow: null,
      onchangeheaderTable: [],
      headerTable: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "60px",
        },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "120px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",
          width: "450px",
        },
        {
          text: "คงเหลือต้นงวด",
          align: "right",
          sortable: false,
          value: "quantityStart",
          width: "150px",
        },
        {
          text: "แพ็คออกจากออเดอร์",
          align: "right",
          sortable: false,
          value: "quantityOrder",
          width: "170px",
        },
        {
          text: "ตีกลับ",
          align: "right",
          sortable: false,
          value: "quantityReturn",
          width: "80px",
        },
        {
          text: "นำเข้า",
          align: "right",
          sortable: false,
          value: "quantityImport",
          width: "80px",
        },
        {
          text: "นำออก",
          align: "right",
          sortable: false,
          value: "quantityExport",
          width: "80px",
        },
        {
          text: "คงเหลือสิ้นงวด",
          align: "right",
          sortable: false,
          value: "quantityRemain",
          width: "130px",
        },
        {
          text: "รหัสCF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "100px",
        },
        {
          text: "รหัสสินค้า",
          align: "center",
          sortable: false,
          value: "productCode",
          width: "120px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "120px",
        },
        {
          text: "บาร์โค้ด",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "120px",
        },
        {
          text: "ลักษณะ",
          align: "center",
          sortable: false,
          value: "props",
          width: "180px",
        },
        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "180px",
        },
        {
          text: "รายละเอียด",
          align: "center",
          sortable: false,
          value: "detaildialog",
          width: "120px",
        },
      ],
    },
    datasetExcel1: null,
    // คลัง
    warehouseID: 0,
    //ร้าน
    sh_shop_id: { id: 0, name: "--ทั้งหมด--" },
    sh_shop_list: [{ id: 0, name: "--ทั้งหมด--" }],

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // สต็อก
    stockShopId: null,
    stockShopSku: null,

    // validate
    datestartValidate: [],
    dateendValidate: [],
  }),
  computed: {
    showHeaders() {
      return this.summarizeStock.onchangeheaderTable.filter((s) =>
        this.summarizeStock.onchangeheaderTable.includes(s)
      );
    },
  },
  async created() {
    await this.GetShopList();
    this.page_loading = false;
    await this.getWarehouseforBranch();

    // ส่วนของ สลับ row
    this.summarizeStock.onchangeheaderTable = [...this.summarizeStock.headerTable];
    this.changeFormat_DataTabel();
  },

  methods: {
    formatDatetime,
    formatMoney,
    isNumberWNoDot,
    isNumberWithDot,
    isNumberWitMark,
    set_format_date_time,
    format_vat_type,
    format_price,
    GenIE,
    GenPA, 

    CheckValidate() {
      this.datestartValidate = [(v) => !!v || "กรุณาระบุ"];
      this.dateendValidate = [(v) => !!v || "กรุณาระบุ"];
    },

    async exportexcel() {
      this.datasetExcel = [];
      this.loadingExcel = 0;
      //setค่าexport ตอนexportออกมา
      this.ExcelName =  "ภาพรวมการเดินสต๊อก" + " ตั้งต่วันที่ " + this.startDate + " - " +  this.endDate + ".xlsx";
      var ExcelHeader = this.headerExcel.map(function (item) { return item.label; });

      //เรียกใช้Export
      var limits = 10;
      var offsets = 0;
      this.loadingExcel = 1;
      var total = this.total;
      var round = parseInt(total / limits);

      if (limits * round < total) {
        round = round + 1;
      }
      for (var i = 1; i <= round; i++) {
        var data = await this.GetExportExcelSummarizeStock(limits, offsets);
        for (var j of data) {
          var rowData = [];
          this.headerExcel.forEach(excelHeaderName => {
            rowData.push(j[excelHeaderName.field]);
          });
          this.datasetExcel.push(rowData);
        }

       
        this.loadingExcel = (this.datasetExcel.length * 100) / total;
        if (round < 100) {
          this.loadingExcel = parseInt(this.loadingExcel);
        } else {
          this.loadingExcel = this.loadingExcel.toFixed(1);
        }
        //เพิ่มtake
        offsets = offsets + limits;
      }
      this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100;
    
    
      
      const ws1 = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws1, [ExcelHeader]);
      utils.sheet_add_json(ws1, this.datasetExcel, { origin: 'A2', skipHeader: true });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "sheet1");
      writeFileXLSX(wb, this.ExcelName);


    },
    async GetExportExcelSummarizeStock(limits, offsets) {
      try {
        let response = await axios.post(
          stockService_dotnet + "Stock/get-summarize-stock-balance-by-date",
          {
            shopID: this.sh_shop_id["id"],
            warehouseID: this.cb_warehouse_selected.id,
            startDate: this.startDate,
            endDate: this.endDate,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
        if (response.data.status === "success") {
          return response.data.data;
        }
      } catch {
        this.$swal({
          toast: true,
          timer: 3000,
          showConfirmButton: false,
          position: "center",
          type: "error",
          title: "เกิดข้อผิดพลาดไม่สามารถดาวน์โหลดได้",
        });
      }
    },

    async GetShopList() {
      this.page_loading = true;
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list ",
        { 
          shopType: 1,
          branchId: localStorage.getItem("Branch_BranchID") },
        { headers: this.header_token }
      );
      if (response.status === 200) {
        this.page_loading = false;
        if (response.data.result !== "" && response.data.result !== null) {
          if (response.data.result.length > 0) {
            this.sh_shop_list = [{ id: 0, name: "--ทั้งหมด--" }];
            for (var x in response.data.result) {
              this.sh_shop_list.push({
                id: response.data.result[x].shop.id,
                name: response.data.result[x].shop.name,
              });
            }
          }
        }
      }
    },
    async getWarehouseforBranch() {
      let response = await axios.post(
        branchService_dotnet +
          "Branch/get-warehouse-by-branchid?BranchID=" +
          localStorage.getItem("Branch_BranchID"),
        {},
        { headers: this.header_token }
      );
      if (response.status === 200) {
        this.warehouse_list = response.data.warehouseList;

        this.cb_warehouse_selected = this.warehouse_list[0];
      }
    },
    async HandleFindStockinfoClick(limits, offsets, page) {
      await this.CheckValidate();
      if (this.$refs.findStock.validate()) {
        this.loading = true;
        this.loadingExcel = 0;
        let response = await axios.post(
          stockService_dotnet + "Stock/get-summarize-stock-balance-by-date",
          {
            shopID: this.sh_shop_id["id"],
            warehouseID: this.cb_warehouse_selected.id,
            startDate: this.startDate,
            endDate: this.endDate,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          if (response.data.status === "success") {
            if (response.data.data === null) {
              Swal.fire({
                position: "top",
                type: "error",
                text: "ไม่มีข้อมูลที่ค้นหา",
                showConfirmButton: false,
                timer: 1500,
              });
              this.summarizeStock.data = [];
              this.loading = false;
              return;
            }
            this.page = page;
            this.pageTemp = page;
            this.offsets = offsets;
            this.total = response.data.totalResult;
            this.pageLength = Math.ceil(this.total / this.limits);
            if (this.pageLength <= 0) {
              this.pageLength = 1;
            }
            this.summarizeStock.data = response.data.data;
            this.showTable = true;
            this.loading = false;
          }
        } else {
          this.loading = false;
          Swal.fire({
            position: "top",
            type: "error",
            text: "เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async detailDialog(item) {
      this.summarizeStock.dialog = true;
      this.stockShopId = item.stockShopID;
      this.stockShopSku = item.sku;
      this.loadingExcel1 = 0;
      this.dataset2 = [];
      await this.SearchStockShopHistory();
    },
    SetDataExcel(data) {
      var response = [];
      for (var i in data) {
        response.push({
          createdDatetime: this.formatDatetime(data[i].createdDatetime),
          referenceNo: this.CheckReferenceNo(data[i].referenceNo),
          description: data[i].description,
          addQuantity: this.formatMoney(data[i].addQuantity),
          removeQuantity: this.formatMoney(data[i].removeQuantity),
          totalQuantity: this.formatMoney(data[i].totalQuantity),
        });
      }
      return response;
    },
    async SearchStockShopHistory() {
      this.loading = true;
      let response = await axios.post(
        stockService_dotnet + "Stock/get-stockshop-history-list",
        {
          shopId: this.sh_shop_id["id"],
          stockShopId: this.stockShopId,
          warehouseId: this.cb_warehouse_selected.id,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        this.dataset2 = await this.SetDataExcel(response.data.itemList);
      }
    },

    async exportexcelDialog() {
      this.datasetExcel1 = [];
      this.loadingExcel1 = 1;

      this.ExcelName1 = "รายละเอียดสต๊อก SKU :" + this.stockShopSku + ".xlsx";
      let response = await axios.post(
        stockService_dotnet + "Stock/get-stockshop-history-list",
        {
          shopId: this.sh_shop_id["id"],
          stockShopId: this.stockShopId,
          warehouseId: this.cb_warehouse_selected.id,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        for (var i of response.data.itemList) {
          this.datasetExcel1.push({
            createdDatetime: formatDatetime(i.createdDatetime),
            referenceNo: i.referenceNo,
            description: i.description,
            addQuantity: i.addQuantity,
            removeQuantity: i.removeQuantity,
            totalQuantity: i.totalQuantity,
          });
        }
        console.log(this.loadingExcel1);
        this.loadingExcel1 = this.datasetExcel1.length == 0 ? 0 : 100;
        document.getElementById("GetExcel").click();
      } else {
        this.$swal({
          toast: true,
          timer: 3000,
          showConfirmButton: false,
          position: "center",
          type: "error",
          title: response.data.message,
        });
      }
    },

    async changeFormat_DataTabel() {
      let remove = ["รหัสCF", "รหัสสินค้า", "SKU", "บาร์โค้ด", "ลักษณะ"];

      this.summarizeStock.onchangeheaderTable = [...this.summarizeStock.headerTable];

      if (this.formatDataTabel) {
        for (var item in remove) {
          let index = this.summarizeStock.onchangeheaderTable.findIndex(
            (x) => x.text === remove[item]
          );
          if (index != -1) {
            this.summarizeStock.onchangeheaderTable.splice(index, 1);
          }
        }
      }

      this.formatDataTabel = !this.formatDataTabel;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async ChangePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = this.page * this.limits - this.limits;
        this.HandleFindStockinfoClick(this.limits, offsets, this.page);
        this.pageTemp = this.page;
      }
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    CheckDateTime(index, startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate;
        var PendDate = endDate;
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate;
          PendDate = startDate;
        }
        startDate = PstartDate;
        endDate = PendDate;
      }
      if (index == 0) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateStart = this.formatDate(startDate);
        this.dateEnd = this.formatDate(endDate);
      }
    },
    async NewPage(ReferenceNo) {
      if (ReferenceNo == null) {
        return ReferenceNo;
      }
      var paie = ReferenceNo.substr(0, 2);
      var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length));
      if (paie == "PA") {
        let response = await axios.post(
          stockService_dotnet + "Stock/get-order-token",
          {
            orderId: ID,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          window.open("order-detail?token=" + response.data);
        }
      } else if (paie == "IE") {
        window.open("transfer-list/update?id=" + btoa(ID));
      }
    },
    CheckReferenceNo(ReferenceNo) {
      if (ReferenceNo == null) {
        return ReferenceNo;
      }
      var paie = ReferenceNo.substr(0, 2);
      var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length));
      if (paie == "PA") {
        return this.GenPA(ID);
      } else if (paie == "IE") {
        return this.GenIE(ID);
      } else {
        return ReferenceNo;
      }
    },
    async EditStock(ImportExportMasterID) {
      window.open("transfer-list/update?id=" + btoa(ImportExportMasterID));
    },
    gotoStock_InFo(item) {
      window.open("stock-info?id=" + item.stockShopID);
    },
  },
};
</script>

<style></style>
